@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap);
body {
  margin: 0;
  font-family: 'Josefin Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-behavior: auto;
}
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    -webkit-animation-delay: -1ms;
    animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
    animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    background-attachment: scroll;
    background-attachment: initial;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}

html {
  font: 400 1rem/1.5 system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu,
    Cantarell, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  background: inherit;
  vertical-align: baseline;
  word-break: normal;
  color: inherit;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
  overflow: visible;
}
hr:not([size]) {
  height: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5rem;
  margin: 0 initial 0.5rem;
  font: inherit inherit 500 unset/1.2 inherit;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin: 0 0 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  font: normal inherit inherit/inherit inherit;
  margin: 0 0 1rem;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin: 0 0 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin: 0;
}

nav li {
  list-style: none;
  padding: 0;
}
nav li::before {
  content: "​";
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: currentColor;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  cursor: pointer;
  color: #3333e1;
  background-color: transparent;
  text-decoration: none;
  word-break: break-word;
}
a:hover {
  color: #3434e0;
  text-decoration: underline;
}
a:focus {
  outline: thin dotted;
}
a:not([href]):not(class),
a:not([href]):not([class]):hover {
  cursor: default;
  color: inherit;
  text-decoration: none;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  touch-action: manipulation;
}

pre,
code,
kbd,
samp {
  font: 1.05em "Fira Code", "Cascadia Code", Consolas, "Inconsolata", Monaco,
    Menlo, "Noto Mono", "Roboto Mono", "Droid Sans Mono", "Ubuntu Mono",
    "Ubuntu Monospace", "Source Code Pro", "Oxygen Mono", "Liberation Mono",
    "DejaVu Sans Mono", "Bitstream Vera Sans Mono", monospace,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.875em;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: inherit;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: inherit;
  background-color: inherit;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

iframe,
img,
input,
select,
textarea {
  height: auto;
  max-width: 100%;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

svg:not([fill]) {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
  text-indent: 0;
  border-color: currentColor;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: inherit;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
  text-transform: none;
}
button:focus {
  outline: dotted 1px;
  outline: -webkit-focus-ring-color auto 5px;
}

input,
button {
  overflow: visible;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border: 1px solid WindowFrame;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  padding: 0.25em 0.375em;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  text-transform: none;
  word-wrap: normal;
}
select:not([multiple]):not([size]) {
  background-image: "data:image/svg+xmlcharset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E";
}

::-ms-expand {
  display: none;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(disabled),
[type="button"]:not(disabled),
[type="reset"]:not(disabled),
[type="submit"]:not(disabled) {
  cursor: pointer;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

textarea {
  overflow: auto;
  -ms-overflow-style: scrollbar;
  resize: vertical;
  vertical-align: top;
}

fieldset {
  min-width: 0;
  padding: 0.35em 0.75em 0.625em;
  margin: 0;
  border: 1px solid inherit;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  max-width: 100%;
  white-space: normal;
}
@media (max-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

dialog {
  background-color: inherit;
  border: solid;
  color: inherit;
  display: block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

input::-webkit-input-placeholder {
  opacity: 1;
}

input:-ms-input-placeholder {
  opacity: 1;
}

input::placeholder {
  opacity: 1;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

::-moz-ui-invalid {
  box-shadow: none;
}

::-moz-focusring {
  outline: 1px dotted ButtonText;
}

output {
  display: inline-block;
}

iframe {
  border: none;
}

audio,
canvas,
video {
  display: inline;
  display: inline-block;
  zoom: 1;
  max-width: 100%;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

[aria-hidden="false"][hidden="false"] {
  display: inline;
  display: initial;
}
[aria-hidden="false"][hidden="false"]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}
.App {
  text-align: center;
  overflow-x: hidden;
  position: relative;
  background-color: whitesmoke;
}

html {
  overflow-x: hidden;
} 

.foreground {
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
}

.section {
  background-color: #05050500;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  scroll-snap-align: start;
  z-index: 1;
  opacity: 1;
}

.background {
  background-color: #050505;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: -1;
}

h1 {
  font-weight: 900;
  color: #422D30;
  font-size: 8em;
  font-family: 'Josefin Sans' local('Gilroy'), url(/static/media/gilroy-extrabold.0f6e082f.otf) sans-serif;
}

h2 {
  font-weight: 600;
  font-size: 6em;
  font-family: local('Gilroy'), url(/static/media/gilroy-extrabold.0f6e082f.otf) 'Josefin Sans' sans-serif;
  color: #422D30;
  -webkit-filter: drop-shadow(0 0 1rem #422D3055);
          filter: drop-shadow(0 0 1rem #422D3055);
}

p {
  font-weight: 300;
  -webkit-filter: drop-shadow(0 0 0.2rem #422D3033);
          filter: drop-shadow(0 0 0.2rem #422D3033);
  color: #422D30;
  font-size: 1.25em;
  line-height: 1.5;
  font-family: 'Lato' url(/static/media/Lato-Regular.fa62c04b.ttf) sans-serif;
}

.link-grid {
  display: grid;
  width: 75%;
  grid-template-columns: 50% 50%;
  grid-gap: 5%;
}

/* #cover,
#clay-cover {
  max-width: 75vw;
  height: auto;
  padding: 50px;
  display: block;
  position: absolute;
  z-index: 0;
} */

.image-before {
  position: fixed;
  width: 75vw;
  height: 75vh;
  z-index: 1;
  background: url(/static/media/clay-cover.19050868.jpg) no-repeat center;
  border: 10px solid #B0A88B;
}

.image-after {
  position: fixed;
  width: 75vw;
  height: 75vh;
  z-index: 0;
  background: url(/static/media/cover-image.ea24df38.jpg) no-repeat center;
}

.clouds-cover {
  border-radius: 30px;
  -webkit-filter: drop-shadow(0 0 0.75rem #422D3088);
          filter: drop-shadow(0 0 0.75rem #422D3088);
}

.announcement {
  color: #422D30;
  justify-self: center;
  align-self: end;
  max-width: 80vw;
}

.announcement-grid {
  display: grid;
  grid-template-rows: 20% 80%;
  min-height: 90vh;
}

.subgrid {
  display: grid;
  grid-gap: 5%;
  grid-template-columns: 66.6% 33.3%;
  padding: 5%;
  align-self: center;
  justify-self: center;
}

.announcement-button {
  color: #422D30;
  border: 2px solid #422D30;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  border-radius: 50px;
  font-family: 'Lato' url(/static/media/Lato-Regular.fa62c04b.ttf) sans-serif;
}

.link-grid-announcement {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 5%;
}

.about img {
  position: fixed;
  z-index: 0;
}

#header-text {
  position: absolute;
  z-index: 10;
}

button {
  background-color: #422D30;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 50px;
  margin: 10px 0px;
  cursor: pointer;
  border: none;
}

/* Platform Buttons */

.platform-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #422D30;
  font-size: 1.5em;
  font-family: 'Lato' url(/static/media/Lato-Regular.fa62c04b.ttf) sans-serif;
  font-weight: 900;
  text-decoration: none;
  margin: 10px 0;
  border: 2px solid #422D30;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  transition: 1s all ease;
  min-height: 75px;
  border-radius: 100px;
}

.button::before{
  background: #422D30;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all 0.6s ease;
}

.button:hover {
  color: #050505;
  text-decoration: none;
}

.button::before{
  width: 100%;
  height: 0%;
  -webkit-transform: translate(-50%,-50%) rotate(-45deg);
          transform: translate(-50%,-50%) rotate(-45deg);
}

.button:hover::before{
  height: 1000%;
}

.button-disabled {
  pointer-events: none;
  color: gray;
  border-color: gray;
}

/* Email Input */

.hidden-input {
  position: absolute; 
  left: -5000px;
}

.subscribe {
  font-weight: 600;
  font-size: 6em;
  font-family: local('Gilroy'), url(/static/media/gilroy-extrabold.0f6e082f.otf) 'Josefin Sans' sans-serif;
  color: #422D30;
  -webkit-filter: drop-shadow(0 0 1rem #B0A88B55);
          filter: drop-shadow(0 0 1rem #B0A88B55);
  display: block;
}

.email-input {
  border: 2px solid #422D30;
  box-sizing: border-box;
  font-family: 'Lato' url(/static/media/Lato-Regular.fa62c04b.ttf) sans-serif;
  font-size: 1.25em;
  width: 100%;
  height: 100%;
  color: #422D30;
  background-color: #00000000;
  text-align: center;
  overflow: hidden;
  transition: 1s all ease;
  position: relative;
  border-radius: 0;
  border-radius: 30px 0 0 30px;
}

.newsletter {
  min-height: 100vh;
}

.email-input:focus {
  outline: none;
  background-color: #422D30;
  color: whitesmoke;
}

.form-box {
  background-color: whitesmoke;
  padding: 15vh 15vw;
  -webkit-filter: drop-shadow(0 0 1.5rem #422D3022);
          filter: drop-shadow(0 0 1.5rem #422D3022);
  border-radius: 30px;
}

.inputs-box {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-gap: none;
  margin: 2em;
}

.submit-button {
  color: #422D30;
  background-color: #00000000;
  font-size: 1.25em;
  font-family: 'Lato' url(/static/media/Lato-Regular.fa62c04b.ttf) sans-serif;
  font-weight: 900;
  text-decoration: none;
  border: 2px solid #422D30;
  border-left: none;
  padding: 10px 60px;
  overflow: hidden;
  transition: 1s all ease;
  border-radius: 0 30px 30px 0;
}

.submit-button:hover {
  cursor: pointer;
  color: whitesmoke;
  background-color: #422D30;
}

/* Banner */

.banner {
  position: relative;
  display: flex;
  justify-content: center;
  top: 0px;
  width: 100%;
  background-color: #422D30cc;
}

.banner > a > p {
  margin: 0;
  font-size: 2em;
  padding: 15px;
  color: whitesmoke;
  -webkit-filter: drop-shadow(0 0 0.3rem #00000044);
          filter: drop-shadow(0 0 0.3rem #00000044);
}

.banner > a {
  text-decoration: none;
}


/* Media Queries */

@media (max-width:750px) {
  h1 {
    /* writing-mode: vertical-lr;
    text-orientation: sideways-right; */
    font-size: 4em;
  }
  h2, .subscribe {
    font-size: 4em;
  }
  p {
    padding: 20px;
    line-height: 175%;
  }
  .form-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85vh;
    width: 85vw;
  }
  .inputs-box {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: none;
    margin: 1em;
  }
  .submit-button {
    font-size: 1.5em;
    font-family: 'Lato' url(/static/media/Lato-Regular.fa62c04b.ttf) sans-serif;
    font-weight: 900;
    text-decoration: none;
    border: 2px solid #422D30;
    border-top: none;
    width: 100%;
    text-transform: uppercase;
    overflow: hidden;
    transition: 1s all ease;
    border-radius: 0 0 30px 30px;
  }
  .email-input {
    padding: 15px 0;
    border-radius: 30px 30px 0 0;
  }
  .link-grid {
    width: 75%;
    grid-gap: 10px;
    grid-template-columns: 100%;
  }
  .link-grid > a {
    margin: 0;
  }
  .banner > a > p {
    font-size: 1em;
  }
  
}

@media (max-width: 600px) {
  .subgrid {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
  }
  .link-grid {
    width: 90%;
  }
}

@media (min-width:500px) {
  .foreground {
    padding: 0 10vw;
  }
}

@media (min-width:1200px) {
  .clouds-cover {
    width: 500px;
  }
}

@media (min-width:1500px) {
  .foreground {
    padding: 0 15vw;
  }
  .link-grid {
    grid-template-columns: 33.3% 33.3% 33.3%;
  }
}

@media (min-width:2500px) {
  .foreground {
    padding: 0 15vw;
  }
}

