@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

.App {
  text-align: center;
  overflow-x: hidden;
  position: relative;
  background-color: whitesmoke;
}

html {
  overflow-x: hidden;
} 

.foreground {
  scroll-snap-type: y mandatory;
}

.section {
  background-color: #05050500;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  scroll-snap-align: start;
  z-index: 1;
  opacity: 1;
}

.background {
  background-color: #050505;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: -1;
}

h1 {
  font-weight: 900;
  color: #422D30;
  font-size: 8em;
  font-family: 'Josefin Sans' local('Gilroy'), url(./fonts/gilroy-extrabold.otf) sans-serif;
}

h2 {
  font-weight: 600;
  font-size: 6em;
  font-family: local('Gilroy'), url(./fonts/gilroy-extrabold.otf) 'Josefin Sans' sans-serif;
  color: #422D30;
  filter: drop-shadow(0 0 1rem #422D3055);
}

p {
  font-weight: 300;
  filter: drop-shadow(0 0 0.2rem #422D3033);
  color: #422D30;
  font-size: 1.25em;
  line-height: 1.5;
  font-family: 'Lato' url(./fonts/Lato-Regular.ttf) sans-serif;
}

.link-grid {
  display: grid;
  width: 75%;
  grid-template-columns: 50% 50%;
  grid-gap: 5%;
}

/* #cover,
#clay-cover {
  max-width: 75vw;
  height: auto;
  padding: 50px;
  display: block;
  position: absolute;
  z-index: 0;
} */

.image-before {
  position: fixed;
  width: 75vw;
  height: 75vh;
  z-index: 1;
  background: url(./images/clay-cover.jpg) no-repeat center;
  border: 10px solid #B0A88B;
}

.image-after {
  position: fixed;
  width: 75vw;
  height: 75vh;
  z-index: 0;
  background: url(./images/cover-image.jpg) no-repeat center;
}

.clouds-cover {
  border-radius: 30px;
  filter: drop-shadow(0 0 0.75rem #422D3088);
}

.announcement {
  color: #422D30;
  justify-self: center;
  align-self: end;
  max-width: 80vw;
}

.announcement-grid {
  display: grid;
  grid-template-rows: 20% 80%;
  min-height: 90vh;
}

.subgrid {
  display: grid;
  grid-gap: 5%;
  grid-template-columns: 66.6% 33.3%;
  padding: 5%;
  align-self: center;
  justify-self: center;
}

.announcement-button {
  color: #422D30;
  border: 2px solid #422D30;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  border-radius: 50px;
  font-family: 'Lato' url(./fonts/Lato-Regular.ttf) sans-serif;
}

.link-grid-announcement {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 5%;
}

.about img {
  position: fixed;
  z-index: 0;
}

#header-text {
  position: absolute;
  z-index: 10;
}

button {
  background-color: #422D30;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 50px;
  margin: 10px 0px;
  cursor: pointer;
  border: none;
}

/* Platform Buttons */

.platform-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #422D30;
  font-size: 1.5em;
  font-family: 'Lato' url(./fonts/Lato-Regular.ttf) sans-serif;
  font-weight: 900;
  text-decoration: none;
  margin: 10px 0;
  border: 2px solid #422D30;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  transition: 1s all ease;
  min-height: 75px;
  border-radius: 100px;
}

.button::before{
  background: #422D30;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: all 0.6s ease;
}

.button:hover {
  color: #050505;
  text-decoration: none;
}

.button::before{
  width: 100%;
  height: 0%;
  transform: translate(-50%,-50%) rotate(-45deg);
}

.button:hover::before{
  height: 1000%;
}

.button-disabled {
  pointer-events: none;
  color: gray;
  border-color: gray;
}

/* Email Input */

.hidden-input {
  position: absolute; 
  left: -5000px;
}

.subscribe {
  font-weight: 600;
  font-size: 6em;
  font-family: local('Gilroy'), url(./fonts/gilroy-extrabold.otf) 'Josefin Sans' sans-serif;
  color: #422D30;
  filter: drop-shadow(0 0 1rem #B0A88B55);
  display: block;
}

.email-input {
  border: 2px solid #422D30;
  box-sizing: border-box;
  font-family: 'Lato' url(./fonts/Lato-Regular.ttf) sans-serif;
  font-size: 1.25em;
  width: 100%;
  height: 100%;
  color: #422D30;
  background-color: #00000000;
  text-align: center;
  overflow: hidden;
  transition: 1s all ease;
  position: relative;
  border-radius: 0;
  border-radius: 30px 0 0 30px;
}

.newsletter {
  min-height: 100vh;
}

.email-input:focus {
  outline: none;
  background-color: #422D30;
  color: whitesmoke;
}

.form-box {
  background-color: whitesmoke;
  padding: 15vh 15vw;
  filter: drop-shadow(0 0 1.5rem #422D3022);
  border-radius: 30px;
}

.inputs-box {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-gap: none;
  margin: 2em;
}

.submit-button {
  color: #422D30;
  background-color: #00000000;
  font-size: 1.25em;
  font-family: 'Lato' url(./fonts/Lato-Regular.ttf) sans-serif;
  font-weight: 900;
  text-decoration: none;
  border: 2px solid #422D30;
  border-left: none;
  padding: 10px 60px;
  overflow: hidden;
  transition: 1s all ease;
  border-radius: 0 30px 30px 0;
}

.submit-button:hover {
  cursor: pointer;
  color: whitesmoke;
  background-color: #422D30;
}

/* Banner */

.banner {
  position: relative;
  display: flex;
  justify-content: center;
  top: 0px;
  width: 100%;
  background-color: #422D30cc;
}

.banner > a > p {
  margin: 0;
  font-size: 2em;
  padding: 15px;
  color: whitesmoke;
  filter: drop-shadow(0 0 0.3rem #00000044);
}

.banner > a {
  text-decoration: none;
}


/* Media Queries */

@media (max-width:750px) {
  h1 {
    /* writing-mode: vertical-lr;
    text-orientation: sideways-right; */
    font-size: 4em;
  }
  h2, .subscribe {
    font-size: 4em;
  }
  p {
    padding: 20px;
    line-height: 175%;
  }
  .form-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85vh;
    width: 85vw;
  }
  .inputs-box {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: none;
    margin: 1em;
  }
  .submit-button {
    font-size: 1.5em;
    font-family: 'Lato' url(./fonts/Lato-Regular.ttf) sans-serif;
    font-weight: 900;
    text-decoration: none;
    border: 2px solid #422D30;
    border-top: none;
    width: 100%;
    text-transform: uppercase;
    overflow: hidden;
    transition: 1s all ease;
    border-radius: 0 0 30px 30px;
  }
  .email-input {
    padding: 15px 0;
    border-radius: 30px 30px 0 0;
  }
  .link-grid {
    width: 75%;
    grid-gap: 10px;
    grid-template-columns: 100%;
  }
  .link-grid > a {
    margin: 0;
  }
  .banner > a > p {
    font-size: 1em;
  }
  
}

@media (max-width: 600px) {
  .subgrid {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
  }
  .link-grid {
    width: 90%;
  }
}

@media (min-width:500px) {
  .foreground {
    padding: 0 10vw;
  }
}

@media (min-width:1200px) {
  .clouds-cover {
    width: 500px;
  }
}

@media (min-width:1500px) {
  .foreground {
    padding: 0 15vw;
  }
  .link-grid {
    grid-template-columns: 33.3% 33.3% 33.3%;
  }
}

@media (min-width:2500px) {
  .foreground {
    padding: 0 15vw;
  }
}
